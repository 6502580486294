<template>

<div>
  
  <bar ref="bar" close="true" step="Escribir" :help="instructions"/>
  <play-verse ref="playVerse"/>

</div>

</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import playVerse from '../components/studyUtils/PlayVerse.vue'

export default {
  name: 'study-basic',
  components: {
    bar,
    'play-verse':playVerse
  },
  data() {
    return {
      initEvaluation: 0,
      finishEvaluation: 0,
      resource: '',
      totalSuccessWords:0,
      instructions: {
        title : 'Escribir en Modo Básico',
        text : 'Escribe cualquier palabra o frase que escuches y pulsa enter o enviar para corregirla, puedes enviar tantas palabras o frases como quieras dentro del tiempo de la prueba. Superarás esta prueba cuando aciertes todas las palabras del verso, no te preocupes si no aciertas todas las palabras a la primera, las palabras acertadas se guardarán para el siguiente intento.',
        titleSecondary : 'El modo básico',
        textSecondary : 'En el modo básico realizarás el ejercicio completo de un sólo verso, cuando lo completes pasarás al verso siguiente. De esta manera irás aprendiendo los versos del poema uno a uno. No te preocupes si no aciertas el verso a la primera, las palabras acertadas se guardarán para que lo sigas intentando.',
        key : 'instructionsBasicPlay'
      },
      finished: false
    }
  },
  mounted(){

    const calculatedCaptions = [this.$store.state.step.captions[this.$store.state.step.status - 1]]
    this.totalSuccessWords = calculatedCaptions[0].text.length
    this.initEvaluation = calculatedCaptions[0].init
    this.finishEvaluation = calculatedCaptions[0].finish
    this.resource = 'step/'+this.$store.state.step.id+'/audio'

    this.$store.state.step.redirect = 'study-basic'
    this.$store.state.step.message = 'Vuelve a escuchar el verso'
    if(this.$store.state.fromStart){
      this.init()
    }

    let that = this
    this.$root.$on('onInitPlayVerse',function(){
      that.init()
    })
    this.$root.$on('sendCaptionsPlayVerse',function(data){
      that.sendCaptions(data.captions,data.id)
    })
    this.$root.$on('onFinishChronometer',function(){
      that.$refs.playVerse.disableSend()
      that.finished = true
      if (that.$refs.playVerse.countSentences === 0) {
        that.$store.state.currentRoute = '/instructions'
        that.$router.push(that.$store.state.currentRoute)
      }
    })
  },
  methods: {
    init: function(){
      let calculatedCaptions = this.calculatedCaptions([])
  		if (this.$store.state.attemptId){
  			this.$refs.playVerse.initAudio(this.initEvaluation,this.finishEvaluation,this.$store.state.step.status,this.resource,calculatedCaptions)
  			return
  		}

  		this.$store.state.loading = true
  		this.$http.post(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/basic/attempt', { },
  			{ headers: {
  			    Accept: 'application/json',
  			    Authorization: 'Bearer '+this.$store.state.accessToken
  			}}).then(response => {
  				this.$store.state.attemptId = response.body.id
  				this.$refs.playVerse.initAudio(this.initEvaluation,this.finishEvaluation,this.$store.state.step.status,this.resource,calculatedCaptions)
  			}, function() {
  				this.$store.state.loading = false
  				this.$store.state.refreshHome = true
  				this.$store.state.currentRoute = this.$store.state.errorRedirect
  				this.$router.push(this.$store.state.currentRoute)
  		});
    
    },
    sendCaptions: function(captions,id){
      this.$http.patch(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/basic/attempt/'+this.$store.state.attemptId, {
          captions: captions,
          timezone: this.$store.state.timezone
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        let words = response.body.successfulWords
        let success = response.body.success
        let ids = response.body.ids

        this.$refs.playVerse.notifyCorrection(id , words)

        let calculatedCaptions = this.calculatedCaptions(ids)
        this.$refs.playVerse.viewCaptions(calculatedCaptions)
        if (success){
        	this.$store.state.step.ids = []
          this.$store.state.result = {
              redirection : '/home' 
            , buttonName : 'continuar'
            , basicSuccess : true
            , progressToday : {
                increaseWords : response.body.increaseWords,
                objetiveStreak : response.body.goalStreak
            }
          }
          this.$store.state.currentRoute = '/result'
          this.$router.push(this.$store.state.currentRoute)
        } else if (this.finished && this.$refs.playVerse.countSentences === 0){
          this.$store.state.currentRoute = '/instructions'
          this.$router.push(this.$store.state.currentRoute)
        }
      }, function () {
        this.$refs.playVerse.notifyCorrection(id , 0)
        if (this.finished && this.$refs.playVerse.countSentences === 0){
          this.$store.state.currentRoute = '/instructions'
          this.$router.push(this.$store.state.currentRoute)
        }
      });
    },
    calculatedCaptions: function(ids){
      for (let i in ids){
        let id = parseInt(ids[i])
        if (this.$store.state.step.ids.indexOf(id) === -1){
          this.$store.state.step.ids.push(id)
          this.$store.state.step.successWords++
        }
      }

      const calculatedCaptions = JSON.parse(JSON.stringify([this.$store.state.step.captions[this.$store.state.step.status - 1]]))
      for (let i in calculatedCaptions[0].text){
          if (this.$store.state.step.ids.indexOf(calculatedCaptions[0].text[i].id) === -1){
            calculatedCaptions[0].text[i].text = '***'
          }
      }
      this.$refs.bar.setProgress(this.$store.state.step.successWords,this.totalSuccessWords)
      return calculatedCaptions
    }
  },
  beforeDestroy: function(){
    this.$root.$off('sendCaptionsPlayVerse')
    this.$root.$off('onFinishChronometer')
    this.$root.$off('onInitPlayVerse')
  }
}
</script>

<style>

</style>
